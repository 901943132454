import { lazy } from "react";
import * as roles from "../catalogs/roles";

/* 
  Routes can be enabled only for specific roles adding a property roles: ['Role1', 'Role2']:

  const routes = [
    {
      path: '/mypath',
      component: lazy(() => import("../ComponentPath")),
      roles: [roles.ACCIAL, roles.INVESTOR]
    }
  ]
*/

/*
IMPORTANT - HOW TO CONFIGURE
Routes must contain path, component and roles. users is optional.
Roles should contain one or more of the allowed roles.
Users could contain one or more emails. If users value is set, only the specified users will have acces to the route.
*/

const routes = [
  {
    path: "/",
    component: lazy(() => import("../views/Home/Home")),
    exact: true,
    roles: [roles.ACCIAL, roles.COINVESTOR, roles.FACILITY, roles.INVESTOR, roles.PARTNER, roles.PARTNERADMIN, roles.TRANSACTIONADMIN, roles.ORCAAASLPA, roles.ORCAAASDFA, roles.FUNDADMIN]
  },
  {
    path: "/home",
    component: lazy(() => import("../views/Home/Home")),
    roles: [roles.ACCIAL, roles.COINVESTOR, roles.FACILITY, roles.INVESTOR, roles.PARTNER, roles.PARTNERADMIN, roles.TRANSACTIONADMIN, roles.ORCAAASLPA, roles.ORCAAASDFA, roles.FUNDADMIN]
  },
  // {
  //   path: "/loansoverview/loans",
  //   component: lazy(() => import("../views/LoansOverview/Loans")),
  //   roles: [roles.ACCIAL]
  // },
  // {
  //   path: "/loansoverview/summary/:loanId",
  //   component: lazy(() => import("../views/LoansOverview/LoanSummary")),
  //   roles: [roles.ACCIAL]
  // },
  {
    path: "/invoices",
    component: lazy(() => import("../views/Invoices/Invoices")),
    roles: [roles.ACCIAL, roles.PARTNER, roles.PARTNERADMIN, roles.COINVESTOR, roles.TRANSACTIONADMIN]
  },
  {
    path: "/jobtrigger",
    component: lazy(() => import("../views/JobTrigger/JobTrigger")),
    roles: [roles.ACCIAL]
  },
  {
    path: "/loanschedules",
    component: lazy(() => import("../views/LoanSchedules/LoanSchedules")),
    roles: [roles.ACCIAL, roles.PARTNER, roles.PARTNERADMIN, roles.TRANSACTIONADMIN, roles.FUNDADMIN]
  },
  {
    path: "/loanschedules",
    component: lazy(() => import("../views/LoanSchedules/LoanSchedules")),
    roles: [roles.COINVESTOR],
    users: ["auribem@skandia.com.co"]
  },
  {
    path: "/advanceRequests",
    component: lazy(() => import("../views/AdvanceRequests/AdvanceRequests")),
    roles: [roles.ACCIAL, roles.PARTNER, roles.PARTNERADMIN, roles.TRANSACTIONADMIN]
  },
  {
    path: "/waivers",
    component: lazy(() => import("../views/Waivers/Waivers")),
    roles: [roles.ACCIAL, roles.PARTNER, roles.PARTNERADMIN, roles.COINVESTOR]
  },
  {
    path: "/notifications",
    component: lazy(() => import("../views/Notifications/Notifications")),
    roles: [roles.ACCIAL, roles.COINVESTOR, roles.FACILITY, roles.INVESTOR, roles.PARTNER, roles.PARTNERADMIN, roles.TRANSACTIONADMIN, roles.ORCAAASLPA, roles.ORCAAASDFA, roles.FUNDADMIN]
  },
  {
    path: "/pricer/yieldcoverageratio",
    component: lazy(() => import("../views/Pricer/YieldCoverageRatio/YieldCoverageRatio")),
    roles: [roles.ACCIAL]
  },
  {
    path: "/backoffice/accountmovements",
    component: lazy(() => import("../views/BackOffice/AccountMovements/AccountMovements")),
    roles: [roles.ACCIAL, roles.PARTNER, roles.PARTNERADMIN, roles.TRANSACTIONADMIN]
  },
  {
    path: "/backoffice/accounts",
    component: lazy(() => import("../views/BackOffice/Accounts/Accounts")),
    roles: [roles.ACCIAL, roles.PARTNER, roles.PARTNERADMIN, roles.TRANSACTIONADMIN]
  },
  {
    path: "/backoffice/approvalRequest",
    component: lazy(() => import("../views/BackOffice/ApprovalRequest/ApprovalRequest")),
    roles: [roles.ACCIAL]
  },
  {
    path: "/backoffice/covenants",
    component: lazy(() => import("../views/BackOffice/Covenants/Covenants")),
    roles: [roles.ACCIAL]
  },
  {
    path: "/backoffice/covenantWaivers",
    component: lazy(() => import("../views/BackOffice/CovenantWaivers/CovenantWaivers")),
    roles: [roles.ACCIAL]
  },
  {
    path: "/backoffice/entities",
    component: lazy(() => import("../views/BackOffice/Entities/Entities")),
    roles: [roles.ACCIAL]
  },
  {
    path: "/backoffice/facilities",
    component: lazy(() => import("../views/BackOffice/Facilities/Facilities")),
    roles: [roles.ACCIAL]
  },
  {
    path: "/backoffice/facility/:facilityId",
    component: lazy(() => import("../views/BackOffice/Facilities/components/Facilities/Facility")),
    roles: [roles.ACCIAL]
  },
  {
    path: "/backoffice/facilitydocuments",
    component: lazy(() => import("../views/BackOffice/FacilityDocuments/FacilityDocuments")),
    roles: [roles.ACCIAL, roles.TRANSACTIONADMIN, roles.PARTNER, roles.PARTNERADMIN, roles.COINVESTOR]
  },
  {
    path: "/backoffice/fundaccounts",
    component: lazy(() => import("../views/BackOffice/FundAccounts/FundAccounts")),
    roles: [roles.ACCIAL]
  },
  {
    path: "/backoffice/funds",
    component: lazy(() => import("../views/BackOffice/Funds/Funds")),
    roles: [roles.ACCIAL]
  },
  {
    path: "/backoffice/fund/:fundId",
    component: lazy(() => import("../views/BackOffice/Funds/components/Funds/Fund")),
    roles: [roles.ACCIAL]
  },
  {
    path: "/backoffice/fundLenders",
    component: lazy(() => import("../views/BackOffice/FundLenders/FundLenders")),
    roles: [roles.ACCIAL]
  },
  {
    path: "/backoffice/facilityMarktomarkethedge",
    component: lazy(() => import("../views/BackOffice/MarkToMarketHedge/FacilityMarkToMarketHedge")),
    roles: [roles.ACCIAL, roles.TRANSACTIONADMIN]
  },
  {
    path: "/backoffice/fundMarktomarkethedge",
    component: lazy(() => import("../views/BackOffice/MarkToMarketHedge/FundMarkToMarketHedge")),
    roles: [roles.ACCIAL]
  },
  {
    path: "/backoffice/partnerdocuments",
    component: lazy(() => import("../views/BackOffice/PartnerDocuments/PartnerDocuments")),
    roles: [roles.ACCIAL, roles.TRANSACTIONADMIN, roles.PARTNER, roles.PARTNERADMIN, roles.COINVESTOR]
  },
  {
    path: "/backoffice/partners",
    component: lazy(() => import("../views/BackOffice/Partners/Partners")),
    roles: [roles.ACCIAL]
  },
  {
    path: "/backoffice/partner/:partnerId",
    component: lazy(() => import("../views/BackOffice/Partners/components/Partners/Partner")),
    roles: [roles.ACCIAL]
  },
  {
    path: "/backoffice/pledgedloans",
    component: lazy(() => import("../views/BackOffice/PledgedLoans/PledgedLoans")),
    roles: [roles.ACCIAL, roles.TRANSACTIONADMIN]
  },
  {
    path: "/backoffice/transactionupdates",
    component: lazy(() => import("../views/BackOffice/TransactionUpdates/TransactionUpdates")),
    roles: [roles.ACCIAL]
  },
  {
    path: "/signup",
    component: lazy(() => import("../views/Signup/Signup")),
    roles: [roles.ADMINISTRATOR]
  },
  {
    path: "/changepassword",
    component: lazy(() => import("../views/ChangePassword/ChangePassword")),
    roles: [roles.ACCIAL, roles.COINVESTOR, roles.INVESTOR, roles.PARTNER, roles.PARTNERADMIN, roles.TRANSACTIONADMIN, roles.ORCAAASLPA, roles.ORCAAASDFA, roles.FUNDADMIN]
  },
  {
    path: "/bidashboards/investment",
    component: lazy(() => import("../views/BIDashboards/Investment")),
    roles: [roles.ACCIAL, roles.COINVESTOR, roles.TRANSACTIONADMIN, roles.PARTNER, roles.PARTNERADMIN, roles.ORCAAASDFA]
  },
  {
    path: "/bidashboards/riskanalysis",
    component: lazy(() => import("../views/BIDashboards/RiskAnalysis")),
    roles: [roles.ACCIAL, roles.ORCAAASLPA]
  },
  {
    path: "/bidashboards/riskanalysisarchive",
    component: lazy(() => import("../views/BIDashboards/RiskAnalysisArchive")),
    roles: [roles.ACCIAL, roles.ORCAAASLPA]
  },
  {
    path: "/bidashboards/dataoverview",
    component: lazy(() => import("../views/BIDashboards/DataOverview")),
    roles: [roles.ACCIAL]
  },
  {
    path: "/bidashboards/fund",
    component: lazy(() => import("../views/BIDashboards/Fund")),
    roles: [roles.ACCIAL]
  },
  {
    path: "/bidashboards/fund",
    component: lazy(() => import("../views/BIDashboards/Fund")),
    roles: [roles.COINVESTOR],
    users: ["auribem@skandia.com.co"]
  },
  {
    path: "/bidashboards/portfolioreport",
    component: lazy(() => import("../views/BIDashboards/PortfolioReport")),
    roles: [roles.ACCIAL]
  },
  {
    path: "/backoffice/datasets",
    component: lazy(() => import("../views/BIDashboards/Datasets")),
    roles: [roles.ACCIAL]
  }
  // {
  //   path: "/bidashboards/playground",
  //   component: lazy(() => import("../views/BIDashboards/Playground")),
  //   roles: [roles.ACCIAL]
  // }
];

export default routes;
